//
// IMPORTS
//

@import "reset";
@import "variables";
@import "products";
@import "pagination";
// Syntax highlighting @import is at the bottom of this file

/**************/
/* BASE RULES */
/**************/

html {
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
}
.entry.archive img, .entry.archive iframe, .entry.archive object {
    display: none;
}
.entry {
    font-size: 14px;
    @include mobile {
        padding-left: 0% !important;
    }

}
i.post-icon {
    font-size: 45px;
    display: inline-block;
    color: #00B6E3;
    float: left;
    border: 3px solid #00B6E3;
    padding: 20px;
    float: left;
    margin-right: 20px;
    margin-top: 12px;
}
.home-post-title {
    position: relative;
    font-size: 28px;
    margin-top: 6px;
    padding: 0;
    font-style: normal;
    border-bottom: solid 1px #00B6E3;
    margin-left: 7px;
    a {
        font-size: 28px;
        line-height: 18px;
        color: #333;
        font-family: 'Roboto', sans-serif;
    }

    .post-flag {
      position: absolute;
      right: -10px;
      bottom: -16px;
      width: 30px;
    }
}
.social {
     border-bottom: 1px solid $lightGray;
     border-top: 1px solid $lightGray;
     margin-bottom: 5px;
     margin-top: 5px;
     padding: 5px;
}
iframe {
    max-width: 100% !important;
    width: 100% !important;
}
article {
    clear: both;
}
.clear {
    clear: both;
}
.post-content {
    display: none;
}
.highlighter-rouge .highlight pre {
    overflow-x: auto;
}
.excerpt-picture {
    text-align:center;
    margin-bottom: 35px;
    margin-top: 10px;
    float: left;
    width: 150px;
    height: 150px;
    display: block;
    margin-right: 10px;
    position: relative;
    z-index: 1;
    @include mobile {
      width: 90%;
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
}
.ifNotMobile {
    @include mobile {
      display: none !important;
    }
}
.ifMobile {
    display: none;
    @include mobile {
      display: block;
    }
}
.hasArticle .excerpt-picture {
    display: none;
}
.hasArticle .post-content {
    display: block;
}
body {
	background: $white;
  font: 18px/1.4 $helvetica;
  font-family: 'Roboto', sans-serif;
  color: $darkGray;
}

.container {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 10px;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans',sans-serif;
  color: $darkerGray;
  font-weight: bold;

  line-height: 1.7;
  margin: 1em 0 15px;
  padding: 0;

  @include mobile {
    line-height: 1.4;
  }
}

h1 {
  font-size: 30px;
  a {
    color: inherit;
  }
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
  color: $gray;
}

p {
  margin: 15px 0;
}

a {
  color: $blue;
  text-decoration: none;
	cursor: pointer;
  &:hover, &:active {
    color: $blue;
  }
}

ul, ol {
  margin: 15px 0;
  padding-left: 30px;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol ul, ul ol, ul ul, ol ol {
  margin: 0;
}

ul ul, ol ul {
  list-style-type: circle;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: bold;
}

img {
  max-width: 100%;
}

// Fixes images in popup boxes from Google Translate
.gmnoprint img {
  max-width: none;
}


// Specify the color of the selection
::-moz-selection {
  color: $black;
  background: $lightGray;
}
::selection {
  color: $black;
  background: $lightGray;
}

// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/*********************/
/* LAYOUT / SECTIONS */
/*********************/

//
// .masthead
//

.wrapper-masthead {
  margin-bottom: 50px;
  background-color: #00B6E3 !important;
  border-bottom: 1px solid #333;
}

.masthead {
  padding: 20px 0;

  @include mobile {
    text-align: center;
  }
}

.site-avatar {
  width: 170px;
  height: 170px;
  display: block;
  text-align: center;
  @include mobile {
    float: none;
    display: block;
    margin: 0 auto;
  }

  img {
    border-radius: 5px;
  }
}

.site-info {
  a {
    color: #fff !important;
    text-shadow: 1px 1px 15px #666;
    margin-left: auto;
    margin-right: auto;
  }
  float: none;
  display: block;
  margin: 0 auto;
}

.site-name {
  margin: 0;
  color: $darkGray;
  cursor: pointer;
  font-family: $helveticaNeue;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: 1px;
}

.site-description {
  text-align: center;
  margin: auto;
  color: $gray;
  font-size: 16px;
}

nav {
  float: right;
  margin-top: 23px; // @TODO: Vertically middle align
  font-family: $helveticaNeue;
  font-size: 18px;

  @include mobile {
    float: none;
    margin-top: 9px;
    display: block;
    font-size: 16px;
  }

  a {
    margin-left: 20px;
    color: $darkGray;
    text-align: right;
    font-weight: 300;
    letter-spacing: 1px;

    @include mobile {
      margin: 0 10px;
      color: $blue;
    }
  }
}

//
// .main
//

.posts > .post {
  padding-bottom: 2em;
  border-bottom: 1px solid $lightGray;
}

.posts > .post:last-child {
  padding-bottom: 1em;
  border-bottom: none;
}

.post {
  blockquote {
    margin: 1.8em .8em;
    border-left: 2px solid $gray;
    padding: 0.1em 1em;
    color: $gray;
    font-size: 22px;
    font-style: italic;
  }

  .comments {
    margin-top: 10px;
  }

  .read-more {
    text-transform: uppercase;
    font-size: 15px;
    display: block;
    margin-top: 11px;
    font-style: normal;
    font-weight: bold;
    color: #00B6E3;
  }
}

footer.footer {
  padding: 20px 0;
  text-align: center;
  background-color: #00b6e3!important;
  border-top: 1px solid #333;
  position: relative;
  margin-top: 50px;
}
footer.footer a {
  color: #fff;
}

@import "highlights";
@import "svg-icons";


td.valign{
    vertical-align: middle;
}

.grid-item {
    margin-bottom: 15px !important;
}


h1 {
  font-family: 'Roboto', sans-serif;
  text-align: left;
  font-size: 30px;
}

h2, h3 {
   font-size: 34px;
   font-family: 'Roboto', sans-serif;
}
article.post .entry img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.entry, .entry p {
    font-size: 22px;
    line-height: 41px;
}
.entry.summary {
  line-height: 21px;
  font-style: normal;
  font-size: 18px;
}
div.highlight {
    border: solid #00B6E3;
    margin-bottom: 10px;
}

.entry h2 {
    border-bottom: solid 1px;
}

.entry.summary p {
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
}

.excerpt-picture img {
    height: 150px;
    width: 150px;
    border-radius: 220px; 
    border: solid 2px #00B6E3;
}

.adsbygoogle {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}


.post-title table tr td {
    vertical-align: middle;
}


#tags a {
  color:#333;
}
.cit {
  width: 100%; 
  height: 130px; 
  overflow: hidden; 
  overflow-x:hidden;
}
.social {
  margin-top: 15px;
  border-bottom: 0px;
}
.entry.entry-full img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.relatedPosts .excerpt-picture {
  width: 64px;
}
.relatedPosts .excerpt-picture img {
  height: 64px;
  width: 64px;
  border-radius: 220px;
  border: solid 2px #00B6E3;
}
.relatedPosts .home-post-title a {
  font-size: 20px;
  line-height: 64px;
}

.relatedPosts .post blockquote {
  vertical-align: middle;
  margin: 0px;
}

p.gkdv-socials {
  text-align:center;
}

footer img.footer-robot {
  opacity: 0.3; 
  position:absolute; 
  bottom: 0px; right: 0%; 
  height: 160px;
}

article.post .post-title h1 {
  background-color: #eee;
}

.relatedPosts article.google-auto-placed {
  position: relative;
  top: -80px;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}