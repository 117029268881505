.pagination-wrapper {
  text-align: center;
margin-top: 20px;
}

.pagination {
  display: inline-block;
  margin: 0 0 2rem;
  width: 100%;
  text-align: center;
}
.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination a,
.pagination span {
  border-radius: 3px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  width: 30px;
margin: 0 4px;
border: 1px solid #ddd; /* Gray */
}
