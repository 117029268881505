.product {
	width: 30%;
	height: 120px;
	position: relative;
	text-align: center;
	float: left;
	cursor: pointer;
	span {
		display: block;
	}
	.product-background {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 120px;
		opacity: 0.5;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center top;
	}

	.product-block {
		position: relative;
		z-index: 1;
		height: 120px;
		width: 60%;
		padding-top: 60%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		font-weight: bold;
		color: #333;
		font-size: 13px;
		line-height: 13px;
		vertical-align: middle;
	}
}

.product:hover {
	.product-background {
		opacity: 1;
	}
}

.products {
	clear: both;
	margin-bottom: 100px;
}

.product.toshop .product-block {
	font-size: 30px;
	background: #eee;
	padding-top: 20%;
}
.product.toshop .product-block:hover {
	opacity: 0.8;
}
